"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _jsMd = _interopRequireDefault(require("js-md5"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _vuex = require("vuex");
var _stationSetting = require("@/assets/js/stationSetting");
var _vuePuzzleVcode = _interopRequireDefault(require("vue-puzzle-vcode"));
var _index = require("@/model/login/index");
var _message = require("@/components/dialog/message");
var _ = _interopRequireDefault(require("../../assets/img/rePhoto/1.png"));
var _2 = _interopRequireDefault(require("../../assets/img/rePhoto/2.png"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _i18n = require("@/assets/js/i18n");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _Geetest = _interopRequireDefault(require("@/components/Geetest.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//页面内引用插件
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      voucherInterval: null,
      loginLoading: false,
      // 登录loading
      isShow: false,
      // 用来控制显示是否显示图片滑动验证框
      photoHua: 0,
      //图片滑动验证的错误次数
      img1: _.default,
      img2: _2.default,
      imgs: [],
      slogan: "让金融更高效 让财富更自由",
      appDownLoadImg: "",
      station: "",
      stationsOptions: [],
      sucessData: {},
      loginStatus: "",
      environment: _stationSetting.VUE_APP_ENV,
      isShowBanner: false,
      loginMsg: "",
      loginData: {
        account: ""
      },
      passwordType: "password",
      bannerTitle: "汇信",
      // 登录数据
      formRules: {
        account: [{
          required: true,
          message: this.translateTitle("邮箱/手机号/用户名不能为空"),
          trigger: "blur"
        }],
        pwd: [{
          required: true,
          message: this.translateTitle("密码不能为空"),
          trigger: "blur"
        }, {
          min: 6,
          max: 20,
          message: this.translateTitle("密码长度为 6-20 位字符"),
          trigger: "blur"
        }]
      },
      isShowGeet: false,
      geetParam: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  components: {
    Vcode: _vuePuzzleVcode.default,
    Geetest: _Geetest.default
  },
  created() {
    if (this.environment == "test") {
      this.getChannelList();
    }
    this.imgs.push(_2.default);
    this.isShowBanner = false;
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  mounted() {
    // 获取url参数
    this.getParams();
    // this.initLoginEnter();
  },

  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    ...(0, _vuex.mapActions)({
      changeEnvironment: "user/changeEnvironment"
    }),
    onStationChange(value) {
      this.stationsOptions.forEach(ele => {
        if (ele.app_id == value) {
          localStorage.removeItem("settingList");
          localStorage.setItem("web-id", value);
          localStorage.setItem("stationValue", JSON.stringify(ele));
          let tempValue = ele.name + "-" + ele.channel_id;
          this.changeEnvironment(tempValue);
        }
      });
      window.location.reload();
    },
    getChannelList() {
      const {
        status,
        data
      } = (0, _stationSetting.apiGetChannelList)();
      if (status == 200) {
        this.stationsOptions = data;
        this.stationsOptions.forEach(ele => {
          let tempVal = JSON.parse(localStorage.getItem("stationValue"));
          if (tempVal) {
            if (ele.app_id == tempVal.app_id) {
              this.station = ele.app_id;
              let tempValue = ele.name + "-" + ele.channel_id;
              this.changeEnvironment(tempValue);
            }
          } else {
            if (ele.app_id == _stationSetting.VUE_APP_ID) {
              localStorage.setItem("stationValue", JSON.stringify(ele));
              this.station = ele.app_id;
              let tempValue = ele.name + "-" + ele.channel_id;
              this.changeEnvironment(tempValue);
            }
          }
        });
      }
      // });
    },

    success(geetParam) {
      this.geetParam = geetParam;
      this.onLogin();
      // this.handLoginSuccess(this.sucessData, this.loginStatus, this.loginMsg);
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },

    onCloseVcode() {
      this.isShow = false;
    },
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    getParams() {
      if (this.$route.params && this.$route.params.username) {
        let {
          username
        } = this.$route.params;
        this.loginData.account = username;
      }
    },
    handlePassword() {
      this.passwordType === "password" ? this.passwordType = "" : this.passwordType = "password";
      // this.$nextTick(() => {
      //   this.$refs.password.focus();
      // });
    },

    onFocusAccount(e) {
      // console.log(e.currentTarget.previousElementSibling);
    },
    initLoginEnter() {
      document.onkeyup = e => {
        let keycode = document.all ? event.keyCode : e.which;
        // 在登录界面 且 按得键是回车键enter
        if ((this.$route.path == "/login" || this.$route.path == this.routeSplicing(this.language, "login")) && keycode === 13) {
          this.onLoginShowVcode();
          // this.onLogin();
        }
      };
    },

    onLoginShowVcode() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          // this.isShow = true;
          this.isShowGeet = !this.isShowGeet;
        }
      });
    },
    /* 登录点击 */
    onLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          const reqDate = {
            login_name: this.loginData.account,
            pwd: (0, _jsMd.default)(this.loginData.pwd),
            ...this.geetParam
          };
          this.loginLoading = true;
          // universalAccountV2Login(reqDate).then(rs => {
          (0, _index.universalAccountV2LoginGeet)(reqDate).then(rs => {
            this.loginLoading = false;
            if (rs.status == 200) {
              this.handLoginSuccess(rs.data, rs.status, rs.msg);
              this.sucessData = rs.data;
              localStorage.setItem("userData", JSON.stringify(rs.data));
              this.loginStatus = rs.status;
              this.loginMsg = rs.msg;
            }
          });
        }
      });
    },
    /* 登录成功|失败处理 */
    handLoginSuccess(data, status, msg) {
      let {
        authInfo = {},
        accountInfo,
        tokenInfo,
        auth
      } = data;
      if (status !== 200) {
        (0, _message.showMessage)({
          message: msg ? msg : status
        });
        return;
      }
      const {
        is_bind_security,
        is_check,
        open_google,
        open_email,
        open_mobile
      } = authInfo;
      // 未绑定二次验证进首次绑定页面
      if (is_bind_security == 1) {
        // 写入cookie，存储 token
        _jsCookie.default.set("topcredit_exchange_t", tokenInfo.token);
        // Cookies.set("_token", tokenInfo.token || null, "/hxexchge.com");
        _jsCookie.default.set("_token", tokenInfo.token || null, {
          expires: 1,
          path: "",
          domain: ".hxexchge.com"
        });
        sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);
        let jump = this.$route.fullPath.split("redirect=")[1] || "";
        if (jump) {
          jump = decodeURIComponent(jump);
        }
        // 跳到首次登录绑定
        // this.$router.push({
        //   path: "login/verify",
        //   query: { accountInfo, jump },
        // });
        this.$store.dispatch("user/getUserInfo").then(res => {
          this.successLogin(auth);
        });
        return;
      }

      // 写入cookie，存储 token
      sessionStorage.setItem("topcredit_exchange_t", tokenInfo.token);
      // 跳转二次认证页面
      let jump = this.$route.fullPath.split("redirect=")[1] || "";
      if (jump) {
        jump = decodeURIComponent(jump);
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Login",
        //註冊或登入
        // authenticationMethod: "email", //註冊方式（不一定要）
        userId: accountInfo.account_id //用戶的平台UID
      });

      this.$router.push({
        name: "LoginMobile",
        params: {
          authInfo,
          accountInfo,
          jump
        }
      });
    },
    successLogin() {
      this.$router.push({
        path: "/"
      });
      return true;
    }
  }
};
exports.default = _default;